<template>
  <div>
    <div>
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort">
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th></vs-th>
          <vs-th sort-key=""></vs-th>
          <vs-th sort-key="">MSS ID</vs-th>
          <vs-th sort-key="">Warehouse</vs-th>
          <vs-th sort-key="">Warehouse Area</vs-th>
          <vs-th sort-key=""># Of SKU</vs-th>
          <vs-th sort-key=""># MSS Value</vs-th>
          <vs-th sort-key="">Document Date</vs-th>
          <vs-th sort-key="">Cost Center</vs-th>
          <vs-th sort-key="">COA</vs-th>
          <vs-th sort-key="" v-if="status === 3">MSS Status</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <div class="vx-input-group flex">
                <vs-button size="small" color="primary" icon-pack="feather" icon="icon-edit"
                           v-if="status === 0"
                           title="Edit" style="margin-right: 5px" @click="handleEdit(tr.id)"/>
                <vs-button size="small" color="success" icon-pack="feather" icon="icon-check"
                           v-if="status === 0"
                           title="Send To approval" style="margin-right: 5px" @click="handleSendToApproval(tr.id)"/>
                <vs-button size="small" color="danger" icon-pack="feather" icon="icon-x"
                           v-if="status === 0"
                           title="Cancel" style="margin-right: 5px" @click="handleDelete(tr.id)"/>
                <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye"
                           v-if="status !== 0"
                           title="Detail" style="margin-right: 5px" @click="handleDetails(tr.id)"/>
                <!--              execute it's on approval  -->
                <vs-button size="small" color="success" icon-pack="feather" icon="icon-check"
                           v-if="isApproval === 1"
                           title="Approve" style="margin-right: 5px" @click="handleApprove(tr.id)"/>
                <vs-button size="small" color="danger" icon-pack="feather" icon="icon-x"
                           v-if="isApproval === 1"
                           title="Reject" style="margin-right: 5px" @click="handleReject(tr.id)"/>
                <!--              execute on approved -->
                <vs-button size="small" color="success" icon-pack="feather" icon="icon-check"
                           v-if="status === 2 && remark === 'Waiting For Execute'"
                           title="Execute" style="margin-right: 5px" @click="handleExecute(tr)"/>

              </div>
            </vs-td>
            <vs-td></vs-td>
            <vs-td>{{ tr.code }}</vs-td>
            <vs-td>{{ tr.warehouse }}</vs-td>
            <vs-td>{{ tr.warehouse_area }}</vs-td>
            <vs-td>{{ tr.sku_count }}</vs-td>
            <vs-td>{{ priceFormat(tr.settlement_value) }}</vs-td>
            <vs-td>{{ formatDate(tr.document_date) }}</vs-td>
            <vs-td>{{ tr.cost_center }}</vs-td>
            <vs-td>{{ tr.coa }}</vs-td>
            <vs-td v-if="status === 3">{{ tr.remark }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage"/>
    </div>

    <vs-prompt
      color="primary"
      title="Confirmation"
      @cancel="submitPromptConfirm = false"
      @accept="acceptExecute"
      @close="closePrompt"
      :accept-text="'Yes'"
      :active.sync="submitPromptConfirm">
      <div class="con-exemple-prompt">
        <div class="vx-row mb-12 mt-3">
          <div class="vx-col w-full">
            <span>Posting date</span>
          </div>
          <div class="vx-col w-full">
            <div class="vx-col">
              <template>
                <datepicker
                  name="posting_date_execute"
                  :inline="false"
                  v-model="posting_date_execute"
                  :clearable="false"
                  :disabledDates="disabledDates"
                  placeholder="Select Date">
                </datepicker>
              </template>
            </div>
          </div>
        </div>

      </div>
    </vs-prompt>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },
  props: {
    draw: {
      type: Number,
    },
    status: {
      type: Number,
      default: null,
    },
    selectedWarehouse: {
      type: Object,
      default: null,
    },
    documentDate: {
      type: Date,
      default: null,
    },
    isApproval: {
      type: Number,
      default: null,
    },
    remark: {
      type: String,
      default: null,
    },
    tab: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      deleteId: null,
      action: null,
      table: this.tableDefaultState(),
      checkedAll: false,
      checked: [],
      selectedRouteAssignmentToAssign: [],
      activeTab: 0,
      activeStatus: 1,
      isApprovalMenu: 0,
      submitPromptConfirm: false,
      posting_date_execute: null,
      disabledDates: {},
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "mss.id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/missing-stock-settlement/all-document", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            status: this.status,
            remark: this.remark,
            is_approval: this.isApproval ? this.isApproval : null,
            warehouse_id: this.selectedWarehouse ? this.selectedWarehouse.id : null,
            document_date: (this.documentDate)
              ? moment(this.documentDate).format("YYYY-MM-DD")
              : null,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    handleSendToApproval(id) {
      this.sendToApprovalID = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to send this data to approval",
        accept: this.sendToApproval,
      });
    },
    sendToApproval() {
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/missing-stock-settlement/send-to-approval/" + this.sendToApprovalID)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully send",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleApprove(id) {
      this.approveID = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to approve this data",
        accept: this.acceptApprove,
      });
    },
    acceptApprove() {
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/missing-stock-settlement/approve/" + this.approveID)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully approved",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleDelete(id) {
      this.deleteId = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to Reject this data",
        accept: this.acceptDelete,
      });
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/missing-stock-settlement/cancel/" + this.deleteId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully Reject",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleReject(id) {
      this.rejectedID = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to reject this data",
        accept: this.acceptReject,
      });
    },
    acceptReject() {
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/missing-stock-settlement/reject/" + this.rejectedID)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully rejected",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleExecute(data) {
      this.disabledDates = {
        from: new Date(Date.now()),
        to: new Date(moment(data.document_date).format("YYYY-MM-DD"))
      }

      this.posting_date_execute = data.document_date
      this.submitPromptConfirm = true
      this.id = data.id;
      this.detail = true;
    },
    acceptExecute() {
      let form = new FormData();
      form.append("posting_date", moment(this.posting_date_execute).format("YYYY-MM-DD"));

      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/missing-stock-settlement/execute/" + this.id, form, )
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully execute",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(id) {
      this.$router.push({
        name: "missing-stock-settlement-edit",
        query: {id: id},
      });
    },
    handleDetails(id) {
      if (this.tab === "WaitingApproval") {
        this.activeTab = 0;
        this.isApprovalMenu = 1;
      } else if (this.tab === 'ApprovedApproval') {
        this.activeTab = 1;
        this.isApprovalMenu = 1;
      } else if (this.tab === 'RejectedApproval') {
        this.activeTab = 2;
        this.isApprovalMenu = 1;
      } else if (this.tab === 'WaitingMSS') {
        this.activeTab = 1;
        this.isApprovalMenu = 0;
      } else if (this.tab === 'ApprovedMSS') {
        this.activeTab = 2;
        this.isApprovalMenu = 0;
      } else if (this.tab === 'ClosedMSS') {
        this.activeTab = 3;
        this.isApprovalMenu = 0;
      }

      this.$router.push({
        name: "missing-stock-settlement-view",
        query: {id: id, tab: this.activeTab, is_approval: this.isApprovalMenu},
      });
    },
    utcToLocal(val) {
      if (val) {
        return moment(val)
          .add(-7, "h")
          .utc()
          .local()
          .format("DD MMM YYYY HH:mm:ss");
      }
    },
    onchangeSelectedRouteAssignmentToAssign(e, index, row) {
      if (!this.checked[index]) {
        var fitered = this.selectedRouteAssignmentToAssign.filter((d) => {
          return d.id == row.id;
        });

        if (fitered.length == 0) {
          this.selectedRouteAssignmentToAssign.push(row);
        }
      } else {
        this.selectedRouteAssignmentToAssign.forEach((val, i) => {
          if (val.id == row.id) {
            this.selectedRouteAssignmentToAssign.splice(i, 1);
          }
        });
        this.checkedAll = false;
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    closePrompt() {
      this.submitPromptConfirm = false
    },
  },
  mounted() {
  },
  watch: {
    draw() {
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
